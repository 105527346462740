//Colors
$darkRed: #bf2b37;
$darkRedHover: #aa2631;
$grayBorder: #dcdcdc;
// $grayBorder: #d8d8d8;



//Responsive
$desktop: 1288px;
$tablet: 975px;
$mobile: 767px;
