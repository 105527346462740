@charset "UTF-8";
.lg-hidden {
  display: none; }
  @media (max-width: 975px) {
    .lg-hidden {
      display: block; } }

.sm-hidden {
  display: block; }
  @media (max-width: 975px) {
    .sm-hidden {
      display: none; } }

@media (max-width: 975px) {
  .flex-item,
  .footer-item {
    width: 100% !important;
    min-width: 710px; } }

@media (max-width: 767px) {
  .flex-item,
  .footer-item {
    width: 94% !important;
    min-width: 94%; } }

.page-node-55032 #content h1.title {
  text-align: left;
  margin-top: 0; }

.page-node-55032 #sidebar-second {
  margin-top: 74px; }

.page-node-55032 .boxed-content {
  background: transparent;
  border: none;
  box-shadow: none; }
  .page-node-55032 .boxed-content .content-wrapper {
    padding-left: 0;
    padding-right: 0; }

@media (max-width: 975px) {
  .page-node-55032 #content h1.title {
    font-size: 36px; }
  .page-node-55032 #sidebar-second {
    margin-top: 0; } }

.block-cicc-join-page {
  padding-top: 15px; }
  .block-cicc-join-page .category {
    background: white;
    border: 1px solid #E5E5E5;
    box-shadow: 4px 4px 0px #CCCCCC;
    margin-bottom: 30px;
    padding: 0px 40px; }
    .block-cicc-join-page .category .header-wrapper {
      display: flex;
      align-items: center;
      padding: 15px 0; }
      .block-cicc-join-page .category .header-wrapper h2 {
        text-align: left;
        display: inline-flex; }
      .block-cicc-join-page .category .header-wrapper .icon {
        display: inline-flex;
        max-width: 40px;
        max-height: 40px;
        margin-right: 20px; }
    .block-cicc-join-page .category ul {
      list-style: none;
      padding-left: 1em; }
      .block-cicc-join-page .category ul li:before {
        content: "•";
        color: #bf2b37;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        margin-bottom: 7px; }
  .block-cicc-join-page .sub-category {
    margin: 0 -40px 0 -40px;
    padding: 0 40px; }
    .block-cicc-join-page .sub-category .wrapper {
      border-top: 1px solid #dcdcdc;
      padding: 10px 0 20px; }
      .block-cicc-join-page .sub-category .wrapper a {
        color: inherit;
        text-decoration: none;
        display: flex; }
        .block-cicc-join-page .sub-category .wrapper a .link__inner {
          flex: 1; }
        .block-cicc-join-page .sub-category .wrapper a:after {
          content: '';
          border-radius: 50%;
          display: inline-flex;
          align-self: center;
          background-image: url(../img/sprite.png);
          background-position: 0px 0px;
          width: 43px;
          height: 43px; }
      .block-cicc-join-page .sub-category .wrapper h3 {
        text-align: left; }
    .block-cicc-join-page .sub-category:hover {
      background: #f1f1f1;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc; }
      .block-cicc-join-page .sub-category:hover h3 {
        color: #bf2b37; }
      .block-cicc-join-page .sub-category:hover .wrapper {
        border: none; }
        .block-cicc-join-page .sub-category:hover .wrapper a:after {
          background-image: url(../img/sprite.png);
          background-position: -43px 0px;
          width: 43px;
          height: 43px; }
  .block-cicc-join-page .sub-category:hover + .sub-category .wrapper {
    border: none; }
  .block-cicc-join-page .sub-category:last-child:hover {
    border-bottom: none; }
  @media (max-width: 975px) {
    .block-cicc-join-page {
      padding-top: 23px; }
      .block-cicc-join-page .category {
        padding: 10px 30px 30px; }
      .block-cicc-join-page .sub-category {
        margin: 0 -30px 0 -30px;
        padding: 0 30px; } }

.page-membership-details #sidebar-second {
  margin-top: 75px; }

.page-membership-details #content h1.title {
  text-align: left;
  margin-top: 0; }

.page-membership-details .boxed-content {
  background: transparent;
  border: none;
  box-shadow: none; }
  .page-membership-details .boxed-content .content-wrapper {
    padding-left: 0;
    padding-right: 0; }

.page-membership-details .mobile-only .table {
  display: none; }

@media (max-width: 975px) {
  .page-membership-details #sidebar-second {
    margin-top: 0; }
  .page-membership-details #content h1.title {
    font-size: 36px; }
  .page-membership-details .mobile-only .table {
    display: table; }
    .page-membership-details .mobile-only .table tr {
      border: none !important; } }

.page-membership-details .region-content .block-system {
  background: white;
  border: 1px solid #E5E5E5;
  box-shadow: 4px 4px 0px #CCCCCC;
  padding: 0px 40px;
  margin-top: 35px; }
  .page-membership-details .region-content .block-system .table {
    margin-bottom: 0; }
    .page-membership-details .region-content .block-system .table tbody {
      border: none; }
    .page-membership-details .region-content .block-system .table .btn {
      margin: 15px 0 10px 0;
      font-size: 13px;
      padding: 0.5em 1.5em;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;
      *zoom: 1;
      filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
      background-color: #bf2b37;
      border: 0;
      color: white;
      text-shadow: #571419 0 -1px 0;
      position: relative;
      box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
      border-bottom: solid 1px #bf2b37; }
      .page-membership-details .region-content .block-system .table .btn:hover, .page-membership-details .region-content .block-system .table .btn:focus {
        color: white;
        text-decoration: none;
        background-color: #aa2631; }
    .page-membership-details .region-content .block-system .table tr {
      background: transparent;
      border-bottom: 1px solid #dcdcdc;
      padding: 0; }
      .page-membership-details .region-content .block-system .table tr td, .page-membership-details .region-content .block-system .table tr th {
        border: none;
        text-align: center;
        padding: 20px 0;
        vertical-align: middle; }
        .page-membership-details .region-content .block-system .table tr td .title, .page-membership-details .region-content .block-system .table tr th .title {
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: bold; }
        .page-membership-details .region-content .block-system .table tr td .price, .page-membership-details .region-content .block-system .table tr th .price {
          color: #bf2b37;
          font-weight: bold; }
          .page-membership-details .region-content .block-system .table tr td .price:before, .page-membership-details .region-content .block-system .table tr th .price:before {
            content: '';
            display: inline-flex;
            vertical-align: middle;
            margin-right: 10px;
            background-image: url(../img/sprite.png);
            background-position: -86px -56px;
            width: 18px;
            height: 18px; }
        .page-membership-details .region-content .block-system .table tr td.benefits, .page-membership-details .region-content .block-system .table tr th.benefits {
          width: 50%;
          text-align: left;
          font-size: 16px; }
        .page-membership-details .region-content .block-system .table tr td span.active, .page-membership-details .region-content .block-system .table tr th span.active {
          background-image: url(../img/sprite.png);
          background-position: -57px -43px;
          width: 22px;
          height: 21px;
          display: inline-block; }
      .page-membership-details .region-content .block-system .table tr th.benefits {
        font-weight: normal;
        font-size: 20px; }
      .page-membership-details .region-content .block-system .table tr td.benefits {
        padding-left: 12px; }
        .page-membership-details .region-content .block-system .table tr td.benefits span:before {
          content: "•";
          color: #bf2b37;
          display: inline-block;
          width: 12px;
          margin-left: -12px; }
  @media (max-width: 975px) {
    .page-membership-details .region-content .block-system {
      padding: 0px;
      background: transparent;
      border: none;
      box-shadow: none;
      margin-top: -10px; }
      .page-membership-details .region-content .block-system .tables-wrapper {
        padding: 0px 30px;
        background: white;
        border: 1px solid #E5E5E5;
        box-shadow: 4px 4px 0px #CCCCCC;
        margin-top: 10px; }
      .page-membership-details .region-content .block-system .table tr th .price {
        display: none; }
      .page-membership-details .region-content .block-system .table tr th .title {
        margin-bottom: 0;
        font-size: 22px; }
      .page-membership-details .region-content .block-system .table tr th.benefits {
        font-size: 28px; }
      .page-membership-details .region-content .block-system .table tr td.benefits {
        font-size: 22px; }
      .page-membership-details .region-content .block-system .main-table .table tbody tr:last-child {
        display: none; }
      .page-membership-details .region-content .block-system .mobile-only .table .price {
        font-size: 22px; }
      .page-membership-details .region-content .block-system .mobile-only .table .title {
        font-size: 24px; } }
  @media (max-width: 767px) {
    .page-membership-details .region-content .block-system .table tr th .price {
      display: none; }
    .page-membership-details .region-content .block-system .table tr th .title {
      margin-bottom: 0;
      font-size: 14px; }
    .page-membership-details .region-content .block-system .table tr th.benefits {
      font-size: 16px; }
    .page-membership-details .region-content .block-system .table tr td.benefits {
      font-size: 14px; }
    .page-membership-details .region-content .block-system .mobile-only .table .price {
      font-size: 14px; }
    .page-membership-details .region-content .block-system .mobile-only .table .title {
      font-size: 14px; } }

.region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d,
.region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider {
  background: white none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  box-shadow: 4px 4px 0 #cccccc;
  margin-bottom: 30px;
  padding: 15px;
  text-align: center; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper h2,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper h2 {
    font-size: 24px; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .views-row,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .views-row {
    margin: 35px auto; }
    @media (max-width: 975px) {
      .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .views-row,
      .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .views-row {
        width: 50%;
        float: left; } }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .ajax-progress,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .ajax-progress {
    display: none !important; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager {
    float: none; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager-current,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager-current {
    display: none;
    visibility: hidden; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager-previous,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager-previous {
    padding: 0;
    margin: 0 15px;
    background-image: url(../img/sprite.png);
    background-position: -104px -56px;
    width: 15px;
    height: 17px;
    display: inline-block; }
    .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager-previous a,
    .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager-previous a {
      text-indent: -99999px;
      border-radius: 0;
      box-shadow: none;
      color: transparent;
      margin-bottom: 0;
      padding: 0;
      background-color: transparent;
      border: none; }
  .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager-next,
  .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager-next {
    padding: 0;
    margin: 0 15px;
    background-image: url(../img/sprite.png);
    background-position: -30px -90px;
    width: 15px;
    height: 17px;
    display: inline-block; }
    .region.region-sidebar-second #block-views-d3509577547ef19ef950af344c32943d .block-wrapper .item-list .pager-next a,
    .region.region-sidebar-second #block-cicc-custom-block-supported-members-node-slider .block-wrapper .item-list .pager-next a {
      text-indent: -99999px;
      border-radius: 0;
      box-shadow: none;
      color: transparent;
      margin-bottom: 0;
      padding: 0;
      background-color: transparent;
      border: none; }

.node-news .node-readmore {
  float: left; }

.node-news .service-links {
  float: right;
  overflow: hidden;
  display: flex; }
  .node-news .service-links .service-label {
    align-self: center; }
    @media (max-width: 767px) {
      .node-news .service-links .service-label {
        display: none; } }
  .node-news .service-links ul {
    padding: 0;
    list-style: none;
    display: flex; }
    .node-news .service-links ul li {
      font-size: 13px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      border-radius: 3px;
      filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
      background-color: #bf2b37;
      border: 0;
      color: white;
      text-shadow: #571419 0 -1px 0;
      position: relative;
      box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
      border-bottom: solid 1px #bf2b37;
      width: 38px;
      height: 38px;
      margin: 0 0 0 10px; }
      .node-news .service-links ul li:hover {
        background-color: #aa2631; }
      .node-news .service-links ul li a {
        display: flex;
        align-self: center; }
        .node-news .service-links ul li a img {
          display: none; }
      .node-news .service-links ul li.service-links-facebook a {
        background-image: url(../img/sprite.png);
        background-position: -45px -90px;
        width: 8px;
        height: 17px; }
      .node-news .service-links ul li.service-links-linkedin a {
        background-image: url(../img/sprite.png);
        background-position: -38px -75px;
        width: 15px;
        height: 15px; }
      .node-news .service-links ul li.service-links-twitter a {
        background-image: url(../img/sprite.png);
        background-position: 0px -75px;
        width: 19px;
        height: 15px; }
      .node-news .service-links ul li.service-links-weibo a {
        background-image: url(../img/sprite.png);
        background-position: -19px -75px;
        width: 19px;
        height: 15px; }

.page-node.node-type-supporting-member .boxed-content .region-content {
  display: flex; }

.page-node.node-type-supporting-member .boxed-content #block-system-main {
  width: 60%; }

.page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions {
  width: 35%; }
  .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions .btn-download {
    font-size: 13px;
    padding: 0.5em 1.5em;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
    background-color: #bf2b37;
    border: 0;
    color: white;
    text-shadow: #571419 0 -1px 0;
    position: relative;
    box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
    border-bottom: solid 1px #bf2b37;
    margin-bottom: 10px; }
    .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions .btn-download:hover {
      background-color: #aa2631; }
  .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions .btn-access-online {
    font-size: 13px;
    padding: 0.5em 1.5em;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    background-color: #f3f3f3;
    border: 0;
    color: #333;
    text-shadow: #571419 0 -1px 0;
    position: relative;
    box-shadow: #e6e6e6 0 0.4em 0px, #e6e6e6 0 1px 0 inset;
    border-bottom: solid 1px #f3f3f3;
    margin-bottom: 10px; }
    .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions .btn-access-online:hover {
      background-color: #e6e6e6; }
  .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions .btn-download + .btn-access-online {
    margin-left: 30px; }

@media (max-width: 975px) {
  .page-node.node-type-supporting-member .boxed-content .content-wrapper {
    padding-bottom: 30px; }
  .page-node.node-type-supporting-member .boxed-content .region-content {
    display: block;
    text-align: center; }
  .page-node.node-type-supporting-member .boxed-content #block-system-main,
  .page-node.node-type-supporting-member .boxed-content #block-cicc-custom-block-supported-member-actions {
    width: 100%; } }

.front #supporting_member_slideshow .col-slider.col-xs-4 {
  width: 16.5%; }

.front .view-display-id-block_home_sponsor {
  margin-left: 0; }

.front #block-cicc-custom-block-slider .slider-container .jssor-slides-container .jssor-slides-slide .jssor-slides-slide-content {
  visibility: visible !important; }
  .front #block-cicc-custom-block-slider .slider-container .jssor-slides-container .jssor-slides-slide .jssor-slides-slide-content .slide-content-intro h3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0; }
    .front #block-cicc-custom-block-slider .slider-container .jssor-slides-container .jssor-slides-slide .jssor-slides-slide-content .slide-content-intro h3 a {
      display: block;
      height: 100%;
      text-indent: -999999px; }
