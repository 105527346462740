@include tablet {
    .flex-item,
    .footer-item {
        width: 100%!important;
        min-width: 710px;
    }
}
@include mobile {
    .flex-item,
    .footer-item {
        width: 94%!important;
        min-width: 94%;
    }
}
