// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$back-name: 'back';
$back-x: 104px;
$back-y: 56px;
$back-offset-x: -104px;
$back-offset-y: -56px;
$back-width: 15px;
$back-height: 17px;
$back-total-width: 119px;
$back-total-height: 107px;
$back-image: '../img/sprite.png';
$back: (104px, 56px, -104px, -56px, 15px, 17px, 119px, 107px, '../img/sprite.png', 'back', );
$back-dis-name: 'back_dis';
$back-dis-x: 15px;
$back-dis-y: 90px;
$back-dis-offset-x: -15px;
$back-dis-offset-y: -90px;
$back-dis-width: 15px;
$back-dis-height: 17px;
$back-dis-total-width: 119px;
$back-dis-total-height: 107px;
$back-dis-image: '../img/sprite.png';
$back-dis: (15px, 90px, -15px, -90px, 15px, 17px, 119px, 107px, '../img/sprite.png', 'back_dis', );
$corp-name: 'corp';
$corp-x: 0px;
$corp-y: 43px;
$corp-offset-x: 0px;
$corp-offset-y: -43px;
$corp-width: 29px;
$corp-height: 32px;
$corp-total-width: 119px;
$corp-total-height: 107px;
$corp-image: '../img/sprite.png';
$corp: (0px, 43px, 0px, -43px, 29px, 32px, 119px, 107px, '../img/sprite.png', 'corp', );
$currency-sign-name: 'currency-sign';
$currency-sign-x: 86px;
$currency-sign-y: 56px;
$currency-sign-offset-x: -86px;
$currency-sign-offset-y: -56px;
$currency-sign-width: 18px;
$currency-sign-height: 18px;
$currency-sign-total-width: 119px;
$currency-sign-total-height: 107px;
$currency-sign-image: '../img/sprite.png';
$currency-sign: (86px, 56px, -86px, -56px, 18px, 18px, 119px, 107px, '../img/sprite.png', 'currency-sign', );
$fb-name: 'fb';
$fb-x: 45px;
$fb-y: 90px;
$fb-offset-x: -45px;
$fb-offset-y: -90px;
$fb-width: 8px;
$fb-height: 17px;
$fb-total-width: 119px;
$fb-total-height: 107px;
$fb-image: '../img/sprite.png';
$fb: (45px, 90px, -45px, -90px, 8px, 17px, 119px, 107px, '../img/sprite.png', 'fb', );
$flag-name: 'flag';
$flag-x: 29px;
$flag-y: 43px;
$flag-offset-x: -29px;
$flag-offset-y: -43px;
$flag-width: 28px;
$flag-height: 31px;
$flag-total-width: 119px;
$flag-total-height: 107px;
$flag-image: '../img/sprite.png';
$flag: (29px, 43px, -29px, -43px, 28px, 31px, 119px, 107px, '../img/sprite.png', 'flag', );
$forward-name: 'forward';
$forward-x: 30px;
$forward-y: 90px;
$forward-offset-x: -30px;
$forward-offset-y: -90px;
$forward-width: 15px;
$forward-height: 17px;
$forward-total-width: 119px;
$forward-total-height: 107px;
$forward-image: '../img/sprite.png';
$forward: (30px, 90px, -30px, -90px, 15px, 17px, 119px, 107px, '../img/sprite.png', 'forward', );
$forward-dis-name: 'forward_dis';
$forward-dis-x: 0px;
$forward-dis-y: 90px;
$forward-dis-offset-x: 0px;
$forward-dis-offset-y: -90px;
$forward-dis-width: 15px;
$forward-dis-height: 17px;
$forward-dis-total-width: 119px;
$forward-dis-total-height: 107px;
$forward-dis-image: '../img/sprite.png';
$forward-dis: (0px, 90px, 0px, -90px, 15px, 17px, 119px, 107px, '../img/sprite.png', 'forward_dis', );
$friends-name: 'friends';
$friends-x: 86px;
$friends-y: 0px;
$friends-offset-x: -86px;
$friends-offset-y: 0px;
$friends-width: 33px;
$friends-height: 25px;
$friends-total-width: 119px;
$friends-total-height: 107px;
$friends-image: '../img/sprite.png';
$friends: (86px, 0px, -86px, 0px, 33px, 25px, 119px, 107px, '../img/sprite.png', 'friends', );
$human-name: 'human';
$human-x: 86px;
$human-y: 25px;
$human-offset-x: -86px;
$human-offset-y: -25px;
$human-width: 26px;
$human-height: 31px;
$human-total-width: 119px;
$human-total-height: 107px;
$human-image: '../img/sprite.png';
$human: (86px, 25px, -86px, -25px, 26px, 31px, 119px, 107px, '../img/sprite.png', 'human', );
$in-name: 'in';
$in-x: 38px;
$in-y: 75px;
$in-offset-x: -38px;
$in-offset-y: -75px;
$in-width: 15px;
$in-height: 15px;
$in-total-width: 119px;
$in-total-height: 107px;
$in-image: '../img/sprite.png';
$in: (38px, 75px, -38px, -75px, 15px, 15px, 119px, 107px, '../img/sprite.png', 'in', );
$red-arrow-name: 'red-arrow';
$red-arrow-x: 43px;
$red-arrow-y: 0px;
$red-arrow-offset-x: -43px;
$red-arrow-offset-y: 0px;
$red-arrow-width: 43px;
$red-arrow-height: 43px;
$red-arrow-total-width: 119px;
$red-arrow-total-height: 107px;
$red-arrow-image: '../img/sprite.png';
$red-arrow: (43px, 0px, -43px, 0px, 43px, 43px, 119px, 107px, '../img/sprite.png', 'red-arrow', );
$red-tick-name: 'red-tick';
$red-tick-x: 57px;
$red-tick-y: 43px;
$red-tick-offset-x: -57px;
$red-tick-offset-y: -43px;
$red-tick-width: 22px;
$red-tick-height: 21px;
$red-tick-total-width: 119px;
$red-tick-total-height: 107px;
$red-tick-image: '../img/sprite.png';
$red-tick: (57px, 43px, -57px, -43px, 22px, 21px, 119px, 107px, '../img/sprite.png', 'red-tick', );
$twitter-name: 'twitter';
$twitter-x: 0px;
$twitter-y: 75px;
$twitter-offset-x: 0px;
$twitter-offset-y: -75px;
$twitter-width: 19px;
$twitter-height: 15px;
$twitter-total-width: 119px;
$twitter-total-height: 107px;
$twitter-image: '../img/sprite.png';
$twitter: (0px, 75px, 0px, -75px, 19px, 15px, 119px, 107px, '../img/sprite.png', 'twitter', );
$weibo-name: 'weibo';
$weibo-x: 19px;
$weibo-y: 75px;
$weibo-offset-x: -19px;
$weibo-offset-y: -75px;
$weibo-width: 19px;
$weibo-height: 15px;
$weibo-total-width: 119px;
$weibo-total-height: 107px;
$weibo-image: '../img/sprite.png';
$weibo: (19px, 75px, -19px, -75px, 19px, 15px, 119px, 107px, '../img/sprite.png', 'weibo', );
$white-arrow-name: 'white-arrow';
$white-arrow-x: 0px;
$white-arrow-y: 0px;
$white-arrow-offset-x: 0px;
$white-arrow-offset-y: 0px;
$white-arrow-width: 43px;
$white-arrow-height: 43px;
$white-arrow-total-width: 119px;
$white-arrow-total-height: 107px;
$white-arrow-image: '../img/sprite.png';
$white-arrow: (0px, 0px, 0px, 0px, 43px, 43px, 119px, 107px, '../img/sprite.png', 'white-arrow', );
$spritesheet-width: 119px;
$spritesheet-height: 107px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($back, $back-dis, $corp, $currency-sign, $fb, $flag, $forward, $forward-dis, $friends, $human, $in, $red-arrow, $red-tick, $twitter, $weibo, $white-arrow, );
$spritesheet: (119px, 107px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
