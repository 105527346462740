.lg-hidden {
    display: none;
    @include tablet {
        display: block;
    }
}
.sm-hidden {
    display: block;
    @include tablet {
        display: none;
    }
}
