//join the chamber
.page-node-55032 {
  #content h1.title {
    text-align: left;
    margin-top: 0;
  }
  #sidebar-second {
    margin-top: 74px;
  }
  .boxed-content {
    background: transparent;
    border: none;
    box-shadow: none;
    .content-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include tablet {
    #content h1.title {
      font-size: 36px;
    }
    #sidebar-second {
      margin-top: 0;
    }
  }
}
.block-cicc-join-page{
  padding-top: 15px;
  .category {
    background: white;
    border: 1px solid #E5E5E5;
    box-shadow: 4px 4px 0px #CCCCCC;
    margin-bottom: 30px;
    padding: 0px 40px;

    .header-wrapper {
      display: flex;
      align-items: center;
      padding: 15px 0;
      h2 {
        text-align: left;
        display: inline-flex;
      }
      .icon {
        display: inline-flex;
        max-width: 40px;
        max-height: 40px;
        margin-right: 20px;
      }
    }

    ul {
      list-style: none;
      padding-left: 1em;
      li {
        &:before {
          content: "•";
          color: $darkRed;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
          margin-bottom: 7px;
        }
      }
    }
  }
  .sub-category {
    margin: 0 -40px 0 -40px;
    padding: 0 40px;
    .wrapper {
      border-top: 1px solid $grayBorder;
      padding: 10px 0 20px;
      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        .link__inner {
          flex: 1;
        }
        &:after {
          content:'';
          border-radius: 50%;
          display: inline-flex;
          align-self: center;
          @include sprite($white-arrow);
        }
      }
      h3 {
        text-align: left;
      }
    }

    &:hover {
      background: #f1f1f1;
      border-top: 1px solid $grayBorder;
      border-bottom: 1px solid $grayBorder;
      h3 {
        color:  $darkRed;
      }
      .wrapper {
        border: none;
        a:after {
          @include sprite($red-arrow);
        }
      }
    }
  }
  .sub-category:hover + .sub-category {
    .wrapper {
      border: none;
    }
  }
  .sub-category:last-child {
    &:hover {
      border-bottom: none;
    }
  }
  @include tablet {
    padding-top: 23px;
    .category {
      padding: 10px 30px 30px;
    }
    .sub-category {
      margin: 0 -30px 0 -30px;
      padding: 0 30px;
    }
  }
}

//membership details
.page-membership-details {
  #sidebar-second {
    margin-top: 75px;
  }
  #content h1.title {
    text-align: left;
    margin-top: 0;
  }
  .boxed-content {
    background: transparent;
    border: none;
    box-shadow: none;
    .content-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .mobile-only .table{
      display: none;
  }
  @include tablet {
    #sidebar-second {
      margin-top: 0;
    }
    #content h1.title {
      font-size: 36px;
    }
    .mobile-only .table{
      display: table;
        tr {
          border: none!important;
        }
    }
  }
  .region-content .block-system {
    background: white;
    border: 1px solid #E5E5E5;
    box-shadow: 4px 4px 0px #CCCCCC;
    padding: 0px 40px;
    margin-top: 35px;
    .table {
      margin-bottom: 0;
      tbody {
        border: none;
      }
      .btn {
        margin: 15px 0 10px 0;
        font-size: 13px;
        padding: 0.5em 1.5em;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;
        *zoom: 1;
        filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
        background-color: #bf2b37;
        border: 0;
        color: white;
        text-shadow: #571419 0 -1px 0;
        position: relative;
        box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
        border-bottom: solid 1px #bf2b37;
        &:hover,
        &:focus {
          color: white;
          text-decoration: none;
          background-color: $darkRedHover;
        }
      }
      tr {
        background: transparent;
        border-bottom: 1px solid $grayBorder;
        padding: 0;
        td, th {
          border: none;
          text-align: center;
          padding: 20px 0;
          vertical-align: middle;
          .title {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
          }
          .price {
            color: $darkRed;
            font-weight: bold;
            &:before {
              content: '';
              display: inline-flex;
              vertical-align: middle;
              margin-right: 10px;
              @include sprite($currency-sign);
            }
          }
          &.benefits {
            width: 50%;
            text-align: left;
            font-size: 16px;
          }
          span.active {
            @include sprite($red-tick);
            display: inline-block;
          }
        }
        th.benefits {
          font-weight: normal;
          font-size: 20px;
        }
        td.benefits {
          padding-left: 12px;
          span {
            &:before {
              content: "•";
              color: $darkRed;
              display: inline-block;
              width: 12px;
              margin-left: -12px;
            }
          }
        }
      }
    }
    @include tablet {
        padding: 0px;
        background: transparent;
        border: none;
        box-shadow: none;
        margin-top: -10px;
        .tables-wrapper {
          padding: 0px 30px;
          background: white;
          border: 1px solid #E5E5E5;
          box-shadow: 4px 4px 0px #CCCCCC;
          margin-top: 10px;
        }
      .table {
        tr {
          th {
            .price {
              display: none;
            }
            .title {
              margin-bottom: 0;
              font-size: 22px;
            }
            &.benefits {
              font-size: 28px;
            }
          }
          td.benefits {
            font-size: 22px;
          }
        }
      }
      .main-table {
        .table {
            tbody {
              tr:last-child {
                display: none;
              }
            }
          }
      }
      .mobile-only {
        .table {
        .price {
          font-size: 22px;
        }
        .title {
          font-size: 24px;
        }
      }
      }
    }
    @include mobile {
      .table {
        tr {
          th {
            .price {
              display: none;
            }
            .title {
              margin-bottom: 0;
              font-size: 14px;
            }
            &.benefits {
              font-size: 16px;
            }
          }
          td.benefits {
            font-size: 14px;
          }
        }
      }
      .mobile-only {
        .table {
        .price {
          font-size: 14px;
        }
        .title {
          font-size: 14px;
        }
      }
      }
    }
  }
}

//News page sidebar

.region.region-sidebar-second {
  #block-views-d3509577547ef19ef950af344c32943d,
  #block-cicc-custom-block-supported-members-node-slider {
    background: white none repeat scroll 0 0;
    border: 1px solid #e5e5e5;
    box-shadow: 4px 4px 0 #cccccc;
    margin-bottom: 30px;
    padding: 15px;
    text-align: center;
    .block-wrapper {
      h2 {
        font-size: 24px;
      }
      .views-row {
        margin: 35px auto;
        @include tablet {
          width: 50%;
          float: left;
        }
      }
      .item-list {
        .ajax-progress{
          display: none!important;
        }
        .pager {
          float: none;
        }
        .pager-current {
          display: none;
          visibility: hidden;
        }
        .pager-previous {
          padding: 0;
          margin: 0 15px;
          @include sprite($back);
          display: inline-block;
          a {
            text-indent: -99999px;
            border-radius: 0;
            box-shadow: none;
            color: transparent;
            margin-bottom: 0;
            padding: 0;
            background-color: transparent;
            border: none;
          }
        }
        .pager-next {
          padding: 0;
          margin: 0 15px;
          @include sprite($forward);
          display: inline-block;
          a {
            text-indent: -99999px;
            border-radius: 0;
            box-shadow: none;
            color: transparent;
            margin-bottom: 0;
            padding: 0;
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
}

//Share on News
.node-news {
  .node-readmore {
    float: left;
  }
  .service-links {
    float: right;
    overflow: hidden;
    display: flex;
    .service-label {
      align-self: center;
      @include mobile {
        display: none;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      li {
        font-size: 13px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-radius: 3px;
        filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
        background-color: $darkRed;
        border: 0;
        color: white;
        text-shadow: #571419 0 -1px 0;
        position: relative;
        box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
        border-bottom: solid 1px $darkRed;
        width: 38px;
        height: 38px;
        margin: 0 0 0 10px;
        &:hover {
          background-color: $darkRedHover;
        }
        a {
          display: flex;
          align-self: center;
          img {
            display: none;
          }
        }
          &.service-links-facebook{
            a {
              @include sprite($fb);
            }
          }
          &.service-links-linkedin {
            a {
              @include sprite($in);
            }
          }
          &.service-links-twitter {
            a {
              @include sprite($twitter);
            }
          }
          &.service-links-weibo {
            a {
              @include sprite($weibo);
            }
          }
      }
    }
  }
}

//Publication page
.page-node.node-type-supporting-member {
  .boxed-content {
    .region-content {
      display: flex;
    }
    #block-system-main{
      width: 60%;
    }
    #block-cicc-custom-block-supported-member-actions {
      width: 35%;
      .btn-download {
        font-size: 13px;
        padding: 0.5em 1.5em;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;
        filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBF2B37', endColorstr='#FF6C181F');
        background-color: $darkRed;
        border: 0;
        color: white;
        text-shadow: #571419 0 -1px 0;
        position: relative;
        box-shadow: #8d2029 0 0.4em 0px, #dd6972 0 1px 0 inset;
        border-bottom: solid 1px $darkRed;
        margin-bottom: 10px;
        &:hover {
          background-color: $darkRedHover;
        }
      }
      .btn-access-online {
        font-size: 13px;
        padding: 0.5em 1.5em;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;
        background-color: #f3f3f3;
        border: 0;
        color: #333;
        text-shadow: #571419 0 -1px 0;
        position: relative;
        box-shadow: #e6e6e6 0 0.4em 0px, #e6e6e6 0 1px 0 inset;
        border-bottom: solid 1px #f3f3f3;
        margin-bottom: 10px;
        &:hover {
          background-color: #e6e6e6;
        }
      }
      .btn-download +  .btn-access-online {
        margin-left: 30px;
      }
    }
    @include tablet {
      .content-wrapper {
        padding-bottom: 30px;
      }
      .region-content {
        display: block;
        text-align: center;
      }
      #block-system-main,
      #block-cicc-custom-block-supported-member-actions {
        width: 100%;
      }
  }
}
}
//Homepage
.front {
  #supporting_member_slideshow {
    .col-slider.col-xs-4 {
      width: 16.5%;
  }
  }
  .view-display-id-block_home_sponsor {
    margin-left: 0;
  }
  #block-cicc-custom-block-slider {
    .slider-container .jssor-slides-container .jssor-slides-slide .jssor-slides-slide-content {
      visibility: visible!important;
      .slide-content-intro h3 {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        margin-bottom: 0;
        a {
          display: block;
          height: 100%;
          text-indent: -999999px;
        }
      }
    }

  }
}
