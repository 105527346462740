// resize img
@mixin resize-img{
  width: 100%;
  max-width: 100%;
  display: block;
}

// Responsive
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
